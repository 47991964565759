import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import HomePageTemplate from '../components/HomePageTemplate';
import Layout from '../components/Layout';

const HomePage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout>
			<HomePageTemplate
				title={frontmatter.title}
				meta_title={frontmatter.meta_title}
				meta_description={frontmatter.meta_description}
				heading={frontmatter.heading}
				description={frontmatter.description}
				description2={frontmatter.description2}
				offerings={frontmatter.offerings}
				testimonials={frontmatter.testimonials}
				heading2={frontmatter.heading2}
			/>
		</Layout>
	);
};

HomePage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default HomePage;

export const pageQuery = graphql`
	query IndexPage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			frontmatter {
				title
				meta_title
				meta_description
				heading
				description
				description2
				heading2
				offerings {
					blurbs {
						image
						text
					}
				}
				testimonials {
					author
					quote
				}
			}
		}
	}
`;
