import React from 'react';
import Helmet from 'react-helmet';
import Offerings from '../Offerings';
import GFI_Institute from '../../assets/img/GFI_Institute.jpg';
import RFW from '../../assets/img/RFW.png';
import RFW_EM from '../../assets/img/RFW_EM.png';
import FSI from '../../../static/img/foodserviceinstitute.png';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const HomePageTemplate = ({
	heading,
	description,
	description2,
	offerings,
	heading2,
	meta_title,
	meta_description,
	// testimonials,
}) => (
	<div>
		<Helmet>
			<title>{meta_title}</title>
			<meta name='description' content={meta_description} />
		</Helmet>
		<section className='hero is-bold is-medium has-bg-img'>
			<div className='hero-body'>
				<div className='container'>
					<div className='columns'>
						<div className='column is-6 is-offset-1'>
							<div className='section'>
								<img
									src={GFI_Institute}
									style={{
										borderRadius: '7px',
										boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section className='section section--gradient'>
			<div className='container'>
				<div className='section'>
					<div className='columns'>
						<div className='column is-10 is-offset-1'>
							<div className='content'>
								<div>
									<h3 className='has-text-weight-semibold is-size-2'>
										{heading}
									</h3>
									<div className='columns is-vcentered'>
										<div>
											<img
												className='column is-128x128'
												src={RFW}
												style={{
													borderRadius: '7px',
												}}
											/>
										</div>
										<p className='column' style={{ fontSize: '20px' }}>
											{description}
										</p>
									</div>
									<div className='columns is-vcentered'>
										<p
											className='column is-three-quarters'
											style={{ fontSize: '20px' }}
										>
											{description2}
										</p>
										<div className='column is-one-quarter'>
											<img
												className='is-32x32'
												src={RFW_EM}
												style={{
													borderRadius: '7px',
												}}
											/>
										</div>
									</div>
									<div
										className='columns is-centered'
										style={{ padding: '30px', fontSize: '20px' }}
									>
										<p className='column'>
											To receive more information and to register to earn one or
											all of the certifications listed below, visit or email:
										</p>
									</div>
									<div className='columns is-vcentered'>
										<h1 className='title column'>
											<a
												href='http://www.foodserviceinstitute.com/'
												target='_blank'
												rel='noopener noreferrer'
												style={{ color: '#82000D' }}
											>
												<img src={FSI} />
											</a>
										</h1>
										<Link
											className='button is-primary is-outlined is-large column'
											to='/contact'
										>
											Email Us
										</Link>
									</div>
								</div>
								<Offerings gridItems={offerings.blurbs} />
								<h3 className='has-text-weight-semibold is-size-3'>
									{heading2}
									<a
										href='http://www.foodserviceinstitute.com/'
										target='_blank'
										rel='noopener noreferrer'
									>
										{' '}
										here
									</a>
									.
								</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
);

HomePageTemplate.propTypes = {
	title: PropTypes.string,
	meta_title: PropTypes.string,
	meta_description: PropTypes.string,
	heading: PropTypes.string,
	heading2: PropTypes.string,
	description: PropTypes.string,
	description2: PropTypes.string,
	offerings: PropTypes.shape({
		blurbs: PropTypes.array,
	}),
	testimonials: PropTypes.array,
};

export default HomePageTemplate;
